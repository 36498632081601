<template>
    <div class="h-full">
        <fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange" @onPageChange="onPageChange" :queryParam="queryParam">
            <div slot="buttonGroup" class="h-handle-button">
                <div class="h-b"><el-button type="primary" size="small" @click="add" v-right-code="'Systemnotice:Save'">新增</el-button> </div>
                <div class="h-b"><el-button type="primary" size="small" @click="deleteMultiple" v-right-code="'Systemnotice:Delete'">删除</el-button> </div>
            </div>
            <p slot="elList">
                <el-table ref="sysLogListTable" :data="dataSource.Result" border @selection-change="handleSelectionChange" highlight-current-row>
                    <el-table-column type="selection" width="55" v-if="(!config.isDetailDisplay)"></el-table-column>
                    <el-table-column v-for="(col,index,count)  in dataSource.ColDefs.BodyFieldParams"
                                     :key="index"
                                     :prop="col.FieldName"
                                     :label="col.DisplayName"
                                     :render-header="bindFilter(queryParam,col)"
                                     :fixed="index==0&&(!config||!config.isDetailDisplay)"
                                     :min-width="(!config||!config.isDetailDisplay)?parseInt(col.Width):0"
                                     v-if="col.Visible&&(!config||!config.isDetailDisplay||(config.isDetailDisplay&&index<2))">
                        <template slot-scope="scope">
                            <span v-if="col.FieldName==='NoticeTitle'"><span type="text" @click="rowClick(scope.row)" style="text-decoration:underline;color:#1874CD;cursor:pointer">{{ scope.row[col.FieldName] }}</span></span>
                            <span v-else>  {{ scope.row[col.FieldName] }}</span>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column prop="" label="操作URL" ></el-table-column> -->
                </el-table>
            </p>
        </fixed-list>


    </div>
</template>
<script>
    export default {
        mounted() {
            this.Event.$on(this.config.moduleEvent.initial, () => this.reloadPageList());
            this.Event.$on(this.config.moduleEvent.add, () => this.add());
            this.initialize();
        },
        data() {
            return {
                multipleSelection: [],
                queryParam: {
                    PageIndex: 1,
                    PageSize: 10
                },
                dataSource: {
                    ColDefs: {},
                    Result: [],
                    TotalCount: 0
                },
            }
        },
        props: {
            config: {
                isDetailDisplay: false,
                isButtonlDisplay: false
            },
            option: {}
        },
        methods: {

            reloadPageList: function () {
                this.initialize();
            },
            initialize() {
                this.onPageChange(this.queryParam);
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
                this.$emit("onCheckRow", this.multipleSelection);
            },
            onPageChange(param) {
                var _this = this;
                param.PageParams = { SearchLogTime: _this.value };
                this.queryParam = param;
             
                this.$ajax.query("omsapi/systemnotice/getlist", "post", param, data => {
                    _this.dataSource = data;
                });
            },
            onDataSourceChange(ds) {
                var _this = this;

                _this.dataSource = {
                    ColDefs: {
                        BodyFieldParams: []
                    },
                    Result: [],
                    TotalCount: 0
                };
                _this.$nextTick(function () {
                    _this.dataSource = ds;

                });
            },
            search() {
            this.initialize();
        },
            rowClick(row) {
                this.$ajax.send("omsapi/systemnotice/get", "get", { id: row.Id , date: this.value }, (data) => {
                    this.Event.$emit("clearEditSysLogForm");
                    this.onChangeEditDataSource(data.Result);
                });
            },
            onChangeEditDataSource(data) {
                this.config.isDetailDisplay = true;
                this.$emit("onChangeEditDataSource", data);
            },
            add() {
                this.$ajax.send("omsapi/systemnotice/get", "get", {}, (data) => {
                    this.onChangeEditDataSource(data.Result);
                });
            },
            deleteMultiple() {
                if (this.multipleSelection == null || this.multipleSelection.length == 0) {
                    this.Utils.messageBox(`未选择数据.`, "error");
                    return false;
                }
                var ids = this.Utils.selectionsToArr(this.multipleSelection, "Id");
                this.delete(ids);
            },
            delete(ids){
                var me = this;
                this.Utils.confirm({
                    title: "提示：",
                    content: "删除后不可恢复，请确认?"
                }, () => {
                    this.$ajax.send("omsapi/systemnotice/delete", "post", ids, (data) => {
                        me.onPageChange(me.queryParam);
                        me.Utils.messageBox("删除成功.", "success");
                    });
                }, () => {
                    me.Utils.messageBox("已取消.", "info");
                });
            }
        }

    }
</script>
<style>
</style>